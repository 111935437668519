import React from "react";
import { styles } from "../styles";
import { Divider, Typography } from "@mui/material";
import { reconciledType } from "../utils";
import { NumericFormat } from "react-number-format";

export const ReconciledData = ({
  reconciledData,
  advanceAmount,
  textareaRef,
  status,
}) => {
  return (
    <div style={styles.reconciledContainer}>
      {status === "reconciled_outside" ? (
        <Typography sx={styles.reconciledHeader}>
          Se marcó como conciliado
        </Typography>
      ) : (
        <Typography sx={styles.reconciledHeader}>
          Se creó un <strong>{reconciledType[reconciledData.type]}</strong>
        </Typography>
      )}
      {status === "reconciled_outside" ? (
        <Typography sx={styles.reconciledText}>
          Comprobante:{" "}
          <strong>
            {reconciledData.accounting_receipt_number?.toUpperCase()}
          </strong>
        </Typography>
      ) : (
        <>
          <Typography sx={styles.reconciledText}>
            Comprobante:{" "}
            <strong>
              {reconciledData.accounting_receipt_number?.toUpperCase()}
            </strong>
          </Typography>
          <Typography sx={styles.reconciledText}>
            Nombre del tercero:{" "}
            <strong>{reconciledData.erp_provider?.toUpperCase()}</strong>
          </Typography>
          <Typography sx={styles.reconciledText}>
            Tipo de documento:{" "}
            <strong>{reconciledData.erp_document_type}</strong>
          </Typography>
          {reconciledData.type !== "CC" && (
            <Typography sx={styles.reconciledText}>
              Forma de pago:{" "}
              <strong>{reconciledData.erp_payment_method}</strong>
            </Typography>
          )}
          {reconciledData.erp_cost_center && (
            <Typography sx={styles.reconciledText}>
              Centro de costos:{" "}
              <strong>{reconciledData.erp_cost_center}</strong>
            </Typography>
          )}
          {reconciledData.type !== "CC" &&
            (reconciledData.erpDocuments?.length === 0 ||
              (reconciledData.erpDocuments?.length > 0 &&
                reconciledData.erpDocuments.reduce(
                  (acc, row) => acc + row.amount,
                  0
                ) < advanceAmount)) && (
              <>
                <Typography
                  sx={{
                    ...styles.reconciledText,
                    marginTop: "8px",
                  }}
                >
                  Anticipo por{" "}
                  <NumericFormat
                    value={
                      advanceAmount -
                      reconciledData.erpDocuments?.reduce(
                        (acc, row) => acc + row.amount,
                        0
                      )
                    }
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$"}
                    decimalScale={2}
                  />
                </Typography>
              </>
            )}
          {reconciledData.erpDocuments?.length > 0 && (
            <>
              <Typography
                sx={{
                  ...styles.reconciledText,
                  marginTop: "8px",
                }}
              >
                Abonos a deuda
              </Typography>
              <div style={styles.reconciledTableContainer}>
                <div
                  style={{ ...styles.reconciledTableRow, marginBottom: "8px" }}
                >
                  <Typography sx={styles.reconciledTableHeader}>
                    Documento
                  </Typography>
                  <Typography sx={styles.reconciledTableHeader}>
                    Monto registrado
                  </Typography>
                </div>
                <Divider sx={styles.reconciledDivider} />
                {reconciledData.erpDocuments?.map((document, index) => {
                  return (
                    <>
                      <div style={styles.reconciledTableRow}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#475467",
                          }}
                        >
                          {document.description}
                        </Typography>
                        <Typography sx={styles.reconciledTableText}>
                          <NumericFormat
                            value={document.amount}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        </Typography>
                      </div>
                      {index !== reconciledData.erpDocuments.length - 1 && (
                        <Divider
                          sx={{
                            ...styles.reconciledDivider,
                            marginBottom: "6px",
                          }}
                        />
                      )}
                    </>
                  );
                })}
              </div>
            </>
          )}
        </>
      )}
      {reconciledData.comments && (
        <>
          <Typography
            sx={{
              ...styles.reconciledText,
              marginTop: "8px",
            }}
          >
            Comentarios
          </Typography>
          <textarea
            ref={textareaRef}
            style={styles.reconciledTextarea}
            name="comments"
            value={reconciledData.comments}
            disabled
          />
        </>
      )}
    </div>
  );
};
