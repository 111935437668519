import React from "react";
import { styles } from "../styles";
import { ReconciliationEmpty } from "assets";
import { Typography } from "@mui/material";

export const AccountingReconcileEmptyState = () => {
  return (
    <div style={styles.reconcileEmptyContainer}>
      <ReconciliationEmpty />
      <Typography sx={styles.reconcileEmptyTitle}>Próximamente</Typography>
      <Typography sx={styles.reconcileEmptyText}>
        La funcionalidad de conciliación estará disponible para ayudarte a
        alinear tus movimientos bancarios con los registros contables ya
        existentes
      </Typography>
    </div>
  );
};
