import React from "react";
import { Typography } from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  ArrowNarrowBottomLeftIcon,
  ArrowNarrowUpRightIcon,
  PayanaLogo,
} from "assets";
import { NumericFormat } from "react-number-format";
import { StatusPill } from "commons";

export const BankMovementItem = ({
  selectedMovement,
  movement,
  setSelectedMovement,
  handleSelectMovement,
}) => {
  return (
    <div
      style={
        selectedMovement.id === movement.id
          ? {
              width: "100%",
              height: "106px",
              border: "2px solid #9B8AFB",
              padding: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
              backgroundColor: "#FAFAFF",
              borderRadius: "8px",
            }
          : {
              width: "100%",
              height: "106px",
              border: "1px solid #EAECF0",
              padding: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }
      }
      onClick={() =>
        selectedMovement.id === movement.id
          ? setSelectedMovement({})
          : handleSelectMovement(movement)
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "2px",
        }}
      >
        {movement.is_from_payana && <PayanaLogo width="72px" height="16px" />}
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#344054",
          }}
        >
          {movement.issue_date}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#344054",
          }}
        >
          {movement.description}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {movement.amount > 0 ? (
            <ArrowNarrowBottomLeftIcon width="19px" height="24px" />
          ) : (
            <ArrowNarrowUpRightIcon width="19px" height="24px" />
          )}
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#101828",
            }}
          >
            <NumericFormat
              value={movement.amount}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              decimalScale={2}
            />
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StatusPill status={movement.status} section={"reconciliations"} />
        {movement.status === "reconciled" && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#344054",
            }}
          >
            {movement.reconciledNumber}
          </Typography>
        )}
      </div>
    </div>
  );
};
