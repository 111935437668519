import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as SubscriptionService from "services";
import { ModalSuccessSuscriber } from "./components/ModalSuccessSuscriber";
import { ModalSuscriber } from "./components";
import { ModalInactiveSuscriber } from "./components/ModalInactiveSuscriber";

export const SubscriptionModal = ({
  subscription,
  openWompiModal = () => {},
}) => {
  const [step, setStep] = useState(
    subscription?.subscription?.status === "inactive" ? "inactive" : "initial"
  );

  const [invoicesSubscriptionPending, setInvoicesSubscriptionPending] =
    useState(null);

  const [features, setFeatures] = useState([]);

  const getInvoicesSubscriptionPending = async () => {
    const resp = await SubscriptionService.getInvoicesSubscriptionPending();
    setInvoicesSubscriptionPending(resp.length > 0 ? resp[0] : null);
  };

  const handleSuccessSubscription = () => {
    subscription?.getMe();
    subscription?.hadleCloseModalSubscription();
  };

  const handleSuccessContinue = () => {
    handleSuccessSubscription();
    subscription?.callback();
  };

  const getFeatures = async () => {
    try {
      const respFeatures = await SubscriptionService.getFeatures();
      const featuresFilter = respFeatures.filter(
        (feature) => feature.type === subscription?.type
      );

      setFeatures(featuresFilter);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFeatures();
    getInvoicesSubscriptionPending();
  }, []);

  const handleOpenModalPayment = () => {
    handleSuccessSubscription();
    openWompiModal({
      totalAmount: invoicesSubscriptionPending.amount_total,
      selectedRowsIds: [invoicesSubscriptionPending.id],
    });
    return;
  };

  const renderContent = {
    initial: (
      <ModalSuscriber
        type={subscription?.type}
        features={features}
        handleChangeStep={(stepValue) => setStep(stepValue)}
        getInvoicesSubscriptionPending={getInvoicesSubscriptionPending}
        subscription={subscription}
      />
    ),
    success: (
      <ModalSuccessSuscriber
        handleSuccessContinue={handleSuccessContinue}
        invoicesSubscriptionPending={invoicesSubscriptionPending}
        handleOpenModalPayment={handleOpenModalPayment}
      />
    ),
    inactive: (
      <ModalInactiveSuscriber
        handleSuccessSubscription={handleSuccessSubscription}
        handleOpenModalPayment={handleOpenModalPayment}
        invoicesSubscriptionPending={invoicesSubscriptionPending}
      />
    ),
  };

  return (
    <Modal
      show={subscription?.showModalSubscription}
      onHide={subscription?.hadleCloseModalSubscription}
    >
      <Modal.Body
        style={{
          padding: "24px",
        }}
      >
        {renderContent[step]}
      </Modal.Body>
    </Modal>
  );
};
