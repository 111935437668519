import { requestContainer } from "../requestContainer";

export async function getReconciliationsBanks() {
  return requestContainer({
    method: "get",
    url: `/reconciliations/banks`,
  });
}

export async function getReconciliationsToken() {
  return requestContainer({
    method: "get",
    url: `/reconciliations/get-siigo-token`,
  });
}

export async function GetBankMovements({
  pageIndex,
  status = "all",
  startDate = "all",
  endDate = "all",
  perPage = 100,
  amountSign = "all",
  bankId,
}) {
  return requestContainer({
    method: "get",
    url: `/reconciliations/movements/${bankId}?page=${pageIndex}&status=${status}&start=${startDate}&end=${endDate}&per_page=${perPage}&amountSign=${amountSign}`,
  });
}

export async function GetBankMovementsTotal({
  status = "all",
  startDate = "all",
  endDate = "all",
  amountSign = "all",
  bankId,
}) {
  return requestContainer({
    method: "get",
    url: `/reconciliations/movements/total/${bankId}?status=${status}&start=${startDate}&end=${endDate}&amountSign=${amountSign}`,
  });
}

export async function getBankMovementData(id) {
  return requestContainer({
    method: "get",
    url: `/reconciliations/show-by-movement-id/${id}`,
  });
}

export async function getPayanaBankMovementData(id) {
  return requestContainer({
    method: "get",
    url: `/reconciliations/get-by-id/${id}`,
  });
}

export async function getBankMovementSuppliers(query = "") {
  return requestContainer({
    method: "get",
    url: `/reconciliations/get-providers?query=${query}`,
  });
}

export async function getCostCenters() {
  return requestContainer({
    method: "get",
    url: `/reconciliations/get-cost-centers`,
  });
}

export async function getDocuments(type) {
  return requestContainer({
    method: "get",
    url: `/reconciliations/get-documents-type?documentType=${
      type === "paymentRecord"
        ? "RP"
        : type === "cashRegisterReceipt"
        ? "RC"
        : "CC"
    }`,
  });
}

export async function createPaymentRecord(formData) {
  return requestContainer({
    method: "post",
    url: `/reconciliations/create-payment-record`,
    data: formData,
  });
}

export async function createCashReceipt(formData) {
  return requestContainer({
    method: "post",
    url: `/reconciliations/create-cash-receipt`,
    data: formData,
  });
}

export async function createAccountingReceipt(formData) {
  return requestContainer({
    method: "post",
    url: `/reconciliations/create-accounting-receipt`,
    data: formData,
  });
}

export async function getAccountingReceiptNumber(accountingReceiptId) {
  return requestContainer({
    method: "get",
    url: `/reconciliations/get-accounting-receipt-number/${accountingReceiptId}`,
  });
}

export async function getPayanaMovementReceipts(amount, issueDate) {
  return requestContainer({
    method: "get",
    url: `/reconciliations/get-from-payana?amount=${amount}&issueDate=${issueDate}`,
  });
}

export async function storeAccountingReceipt(formData) {
  return requestContainer({
    method: "post",
    url: `/reconciliations/store`,
    data: formData,
  });
}

export async function getSupplierDues(id, type) {
  return requestContainer({
    method: "get",
    url: `/reconciliations/get-dues/${id}?dueType=${
      type === "paymentRecord" ? "4" : "1"
    }`,
  });
}

export async function checkSiigoCredentials() {
  return requestContainer({
    method: "get",
    url: "/sync/check",
  });
}

export async function getAccountingAccounts() {
  return requestContainer({
    method: "get",
    url: "/reconciliations/get-accounting-accounts",
  });
}

export async function getPaymentMethods(query = "", type = "paymentRecord") {
  return requestContainer({
    method: "get",
    url: `/reconciliations/get-payment-methods?query=${query}&paymentMethodType=${
      type === "paymentRecord" ? "0" : "1"
    }`,
  });
}

export async function importDefaultBankMovements(data) {
  return requestContainer({
    method: "post",
    url: "/reconciliations/movements/import",
    data: data,
  });
}

export async function importBankMovements(data) {
  return requestContainer({
    method: "post",
    url: "/reconciliations/movements/upload-bank-movements",
    data: data,
    customContentType: "multipart/form-data",
  });
}

export async function setReconciliationBankData(data) {
  return requestContainer({
    method: "post",
    url: "/reconciliations/banks",
    data: data,
  });
}

export async function markAsReconciled(data) {
  return requestContainer({
    method: "post",
    url: "/reconciliations/movements/set-reconciled",
    data: data,
  });
}

export function UploadReconciliationsFileCSV2(formData) {
  return requestContainer({
    method: "post",
    url: "/upload/csv/reconciliations",
    data: formData,
  });
}

export function ExportReconciliationsFile(dateFrom, dateTo) {
  return requestContainer({
    method: "get",
    url: `/reconciliations/movements/export?start=${dateFrom}&end=${dateTo}`,
  });
}
