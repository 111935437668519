import React from "react";
import { Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { PrimaryButton } from "../../buttons";

export const NewFeatureModal = ({
  title,
  image = null,
  visible,
  handleClose,
}) => {
  return (
    <Modal show={visible} onHide={handleClose} centered>
      <Modal.Body>
        <Box style={styles.container}>
          <p style={styles.header}>¡Nuevo!</p>
          <p style={styles.title}>{title}</p>
          {image}
          <div style={styles.row}>
            <PrimaryButton
              text="Entendido"
              action={handleClose}
              width="214px"
            />
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};
