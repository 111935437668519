import React, { useState, useEffect, useContext, useRef } from "react";
import { TrackJS } from "trackjs";
import * as ReconciliationsService from "../../services/api/reconciliations/Reconciliations.service";
import { Typography } from "@mui/material";
import {
  TableSkeleton,
  PickDatesToExportModal,
  ButtonWithIcon,
  SiigoIntegrationModal,
  BankExtractModal,
  MarkAsReconciledModal,
  ReconciliationsTrialModal,
} from "../../commons";
import { getFromLocalStorage, saveInLocalStorage } from "../../utils";
import { styles } from "./styles";

import { ReconciliationsContext, UserContext } from "../../contexts";
import { DownloadFileIcon2 } from "../../assets";
import { useAssistedExpenses, useChat } from "../../hooks";
import {
  Accounting,
  BankMovements,
  Banks,
  ReconciliationsEmptyState,
} from "./components";
import { addYears, format } from "date-fns";

import { toast } from "react-toastify";
import { toastOptions } from "constants";
import CsvHandler from "commons/modals/UploadFileModal/CsvHandler";
import { formatDate } from "commons/modals/ExpandAssistedExpenses/utils";
import { useLocation, useNavigate } from "react-router-dom";

export const ReconciliationsScreen = (props) => {
  const [totals, setTotal] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const {
    internalGetBankMovements,
    reconciliationsScreen,
    statusSelected,
    setStatusSelected,
    perPageSelected,
    setPerPageSelected,
    amountSignSelected,
    setAmountSignSelected,
    bankMovements,
    selectedBank,
    setSelectedBank,
    banks,
    setBanks,
  } = useContext(ReconciliationsContext);
  const { getMe, isReconciliationsEnabled, hasSiigoCredentials, currentUser } =
    useContext(UserContext);

  const location = useLocation();

  const pageIndex = reconciliationsScreen?.pageIndex;
  const setPageIndex = reconciliationsScreen?.setPageIndex;

  const beneficiaryIdSelected =
    reconciliationsScreen?.beneficiaryIdSelected?.value;
  const setBeneficiaryIdSelected =
    reconciliationsScreen?.beneficiaryIdSelected?.setter;

  const [reconciliationOption, setReconciliationOption] =
    useState("not_reconciled");

  const [accountingOption, setAccountingOption] = useState("register");
  const [accountingType, setAccountingType] = useState("paymentRecord");

  const [searchOptions, setSearchOptions] = useState([]);
  const [date, setDate] = useState([
    {
      startDate: addYears(new Date(), -1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [type, setType] = useState("all");
  const [selectedDate, setSelectedDate] = useState([
    { startDate: "all", endDate: "all" },
  ]);

  const startDate = date.at(0).startDate
    ? format(date.at(0).startDate, "yyyy-MM-dd")
    : "all";
  const endDate = date.at(0).endDate
    ? format(date.at(0).endDate, "yyyy-MM-dd")
    : "all";

  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [isPageInitialLoading, setIsPageInitialLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  const [selectedMovement, setSelectedMovement] = useState({});
  const [isSiigoModalVisible, setIsSiigoModalVisible] = useState(false);
  const [isBankExtractModalVisible, setIsBankExtractModalVisible] =
    useState(false);
  const [isMarkAsReconciledModalVisible, setIsMarkAsReconciledModalVisible] =
    useState(false);

  const reconciliationsCsvButton = useRef(null);

  const { items, setItems } = useAssistedExpenses({
    isAssistedEgress: false,
    movementAmount:
      selectedMovement.amount < 0
        ? selectedMovement.amount * -1
        : selectedMovement.amount,
  });

  const [costCenters, setCostCenters] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [loadingSupplierItems, setLoadingSupplierItems] = useState(false);
  const [payanaReceipts, setPayanaReceipts] = useState([]);
  const [comment, setComment] = useState("");
  const [reconciledData, setReconciledData] = useState({});
  const [showSiigoIntermittencies, setShowSiigoIntermittencies] =
    useState(false);
  const [accountingAccountOptions, setAccountingAccountOptions] = useState([]);
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [trialModalIsVisible, setTrialModalIsVisible] = useState(
    getFromLocalStorage("isTrialModalVisible") || "true"
  );
  const [movementId, setMovementId] = useState(null);

  useChat();

  const navigate = useNavigate();

  const refreshBankMovementsTable = async () => {
    try {
      setPageLoading(true);
      if (banks.length > 0) {
        await internalGetBankMovements(
          pageIndex,
          statusSelected,
          startDate,
          endDate,
          perPageSelected,
          amountSignSelected,
          selectedBank
        );
        const totalBankMovements =
          await ReconciliationsService.GetBankMovementsTotal({
            status: statusSelected,
            startDate: startDate,
            endDate: endDate,
            amountSign: amountSignSelected,
            bankId: selectedBank.id,
          });
        setTotal(totalBankMovements.at(0));
      }
    } catch (error) {
      TrackJS.console.error(error);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (!hasSiigoCredentials) {
      navigate("/payments", { replace: true });
    }
  }, [hasSiigoCredentials]);

  useEffect(() => {
    getMe().finally(() => {
      if (!isReconciliationsEnabled) {
        navigate("/reconciliations/coming-soon", { replace: true });
      }
    });

    if (reconciliationOption !== "not_reconciled") {
      setReconciliationOption("not_reconciled");
      setStatusSelected("not_reconciled");
    }

    Promise.all([
      ReconciliationsService.getReconciliationsToken(),
      ReconciliationsService.getReconciliationsBanks(),
      ReconciliationsService.getBankMovementSuppliers(),
      checkSiigoCredentials(),
    ])
      .then(([tokenRes, banksRes, suppliersRes, siigoCredentialsRes]) => {
        if (tokenRes?.response?.status !== 400) {
          setShowSiigoIntermittencies(true);
        }
        if (banksRes?.response?.status !== 400) {
          setBanks(banksRes);
          setSelectedBank(banksRes?.at(0));
        } else {
          setBanks([]);
          setSelectedBank(null);
        }

        if (suppliersRes?.response?.status === 400) {
          setSearchOptions([]);
          setShowSiigoIntermittencies(true);
        } else {
          setSearchOptions(suppliersRes);
          setShowSiigoIntermittencies(false);
        }

        if (siigoCredentialsRes) {
          setSyncCS((prev) => ({ ...prev, siigo: true }));
        }
      })
      .finally(() => {
        setFirstLoading(false);
      });
  }, []);

  useEffect(() => {
    refreshBankMovementsTable().then(() => {
      setIsPageInitialLoading(false);
    });
  }, [firstLoading]);

  useEffect(() => {
    if (!isPageInitialLoading) {
      refreshBankMovementsTable();
    }
  }, [
    pageIndex,
    selectedDate,
    amountSignSelected,
    statusSelected,
    selectedBank,
    perPageSelected,
  ]);

  useEffect(() => {
    if (location.state && location.state.importSuccess) {
      ReconciliationsService.getReconciliationsBanks().then((res) => {
        setBanks(res);
        const bankSelected = res.find(
          (bank) =>
            bank.id === parseInt(getFromLocalStorage("reconciliationsBankId"))
        );
        setSelectedBank(bankSelected);
      });
      setShowTrialModal(true);
    }
  }, [location]);

  useEffect(() => {
    if (totals) {
      if (totals.total > perPageSelected) {
        setTotalPages(Math.ceil(totals.total / perPageSelected));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  const getSearchOptions = (query) => {
    ReconciliationsService.getBankMovementSuppliers(query).then((res) => {
      if (res?.response?.status === 400) {
        setSearchOptions([]);
        setShowSiigoIntermittencies(true);
      } else {
        setSearchOptions(res);
        setShowSiigoIntermittencies(false);
      }
    });
  };

  const getPaymentMethodOptions = (query) => {
    ReconciliationsService.getPaymentMethods(query, accountingType).then(
      (res) => {
        setPaymentMethods(res);
      }
    );
  };

  const handleSearchChange = (beneficiary) => {
    if (beneficiary) {
      setLoadingSupplierItems(true);
      setBeneficiaryIdSelected(beneficiary.AccountID);
      reconciliationsScreen?.beneficiaryFilter?.setter(beneficiary);
      let tmpBeneficiaryDues = [];
      Promise.all([
        ReconciliationsService.getSupplierDues(
          beneficiary.AccountID,
          accountingType
        ),
        ReconciliationsService.getCostCenters(),
        ReconciliationsService.getDocuments(accountingType),
        ReconciliationsService.getPaymentMethods("", accountingType),
      ])
        .then(
          ([
            supplierDuesRes,
            costCentersRes,
            documentsRes,
            paymentMethodsRes,
          ]) => {
            if (supplierDuesRes?.response?.status !== 400) {
              supplierDuesRes?.map((item, index) =>
                tmpBeneficiaryDues.push({
                  ...item,
                  id: index,
                  amountRegister: 0,
                  DueDate: formatDate(item.DueDate),
                })
              );
            }
            setItems(tmpBeneficiaryDues);
            setCostCenters(
              costCentersRes?.response?.status === 400 ? [] : costCentersRes
            );
            setDocumentTypes(
              documentsRes?.response?.status === 400 ? [] : documentsRes
            );
            setPaymentMethods(
              paymentMethodsRes?.response?.status === 400
                ? []
                : paymentMethodsRes
            );
          }
        )
        .finally(() => {
          setLoadingSupplierItems(false);
        });
    } else {
      setBeneficiaryIdSelected("all");
      reconciliationsScreen?.beneficiaryFilter?.setter({});
    }
    setPageIndex(1);
  };

  const checkSiigoCredentials = async () => {
    try {
      const resp = await ReconciliationsService.checkSiigoCredentials();
      const syncValidation = resp?.syncValidation;
      return syncValidation?.credentials;
    } catch (error) {
      toast.error(
        "Hubo un error al obtener la información de credenciales",
        toastOptions
      );
    }
  };

  const [syncCS, setSyncCS] = useState({
    siigo: false,
  });

  const checkCredentials = async () => {
    const haveCredentials = await checkSiigoCredentials();
    if (haveCredentials) {
      setSyncCS((prev) => ({ ...prev, siigo: true }));
    }
  };

  const handleSiigoCloseModal = async () => {
    setIsSiigoModalVisible(false);
    checkCredentials();
  };

  const emptyState =
    !pageLoading && !firstLoading && bankMovements && banks.length === 0;

  const resetAccounting = () => {
    setAccountingOption("register");
    setAccountingType("paymentRecord");
    reconciliationsScreen?.beneficiaryFilter?.setter({});
    setSelectedCostCenter(null);
    setComment("");
    setSelectedDocumentType(null);
    setSelectedPaymentMethod(null);
    setReconciledData({});
  };

  return (
    <>
      <SiigoIntegrationModal
        visible={isSiigoModalVisible}
        setIsSiigoModalVisible={handleSiigoCloseModal}
        isReconciliations={true}
      />
      <CsvHandler
        licenseKey={process.env.REACT_APP_CSVBOX_RECONCILIATIONS_LICENSE_KEY}
        buttonRef={reconciliationsCsvButton}
        entity="reconciliations"
      />
      <BankExtractModal
        visible={isBankExtractModalVisible}
        setIsBankExtractModalVisible={setIsBankExtractModalVisible}
        reconciliationsCsvButton={reconciliationsCsvButton}
      />
      <MarkAsReconciledModal
        visible={isMarkAsReconciledModalVisible}
        setIsMarkAsReconciledModalVisible={setIsMarkAsReconciledModalVisible}
        selectedMovement={selectedMovement}
        accountingType={accountingType}
        resetAccounting={resetAccounting}
        setSelectedMovement={setSelectedMovement}
        setReconciliationOption={setReconciliationOption}
        setStatusSelected={setStatusSelected}
      />
      <PickDatesToExportModal
        visible={isExportModalVisible}
        handleClose={() => setIsExportModalVisible(false)}
        entity="reconciliations"
      />
      <ReconciliationsTrialModal
        open={showTrialModal && trialModalIsVisible === "true"}
        onClose={() => {
          saveInLocalStorage("isTrialModalVisible", "false");
          setTrialModalIsVisible("false");
          setShowTrialModal(false);
        }}
      />
      <div
        className="layout-container"
        style={{
          maxHeight: "100vh",
          overflow: "auto",
        }}
      >
        <div style={styles.titleContainer}>
          <Typography sx={styles.title} noWrap>
            Conciliaciones
          </Typography>
          {!isPageInitialLoading && !firstLoading && !emptyState && (
            <ButtonWithIcon
              action={() => setIsExportModalVisible(true)}
              buttonLabel={"Descargar conciliación"}
              icon={
                <DownloadFileIcon2 stroke="#FFFFFF" width="16" height="20" />
              }
              width={"210px"}
            />
          )}
        </div>
        {isPageInitialLoading || firstLoading ? (
          <div className="table-skeleton-page-initial-pageLoading">
            <TableSkeleton />
          </div>
        ) : emptyState ? (
          <ReconciliationsEmptyState
            setIsSiigoModalVisible={setIsSiigoModalVisible}
            setIsBankExtractModalVisible={setIsBankExtractModalVisible}
            syncCS={syncCS}
          />
        ) : (
          <div style={styles.pageContainer}>
            <Banks
              banks={banks}
              setIsBankExtractModalVisible={setIsBankExtractModalVisible}
              selectedBank={selectedBank}
              setSelectedBank={setSelectedBank}
              resetAccounting={resetAccounting}
              setSelectedMovement={setSelectedMovement}
            />
            <div style={styles.columnsContainer}>
              <BankMovements
                bankMovements={bankMovements}
                selectedMovement={selectedMovement}
                setSelectedMovement={setSelectedMovement}
                setPageIndex={setPageIndex}
                reconciliationOption={reconciliationOption}
                setReconciliationOption={setReconciliationOption}
                setStatusSelected={setStatusSelected}
                date={date}
                setDate={setDate}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                type={type}
                setType={setType}
                setTabSelected={setAccountingOption}
                setAccountingType={setAccountingType}
                reconciliationsCsvButton={reconciliationsCsvButton}
                perPageSelected={perPageSelected}
                setPerPageSelected={setPerPageSelected}
                setSelectedCostCenter={setSelectedCostCenter}
                payanaReceipts={payanaReceipts}
                setPayanaReceipts={setPayanaReceipts}
                resetAccounting={resetAccounting}
                setReconciledData={setReconciledData}
                amountSignSelected={amountSignSelected}
                setAmountSignSelected={setAmountSignSelected}
                totalPages={totalPages}
                pageIndex={pageIndex}
                pageLoading={pageLoading}
                setPageLoading={setPageLoading}
                setMovementId={setMovementId}
                selectedBank={selectedBank}
              />
              <Accounting
                accountingOption={accountingOption}
                setAccountingOption={setAccountingOption}
                selectedMovement={selectedMovement}
                searchOptions={searchOptions}
                handleSearchChange={handleSearchChange}
                prevItems={items}
                costCenters={costCenters}
                selectedCostCenter={selectedCostCenter}
                setSelectedCostCenter={setSelectedCostCenter}
                documentTypes={documentTypes}
                paymentMethods={paymentMethods}
                beneficiaryIdSelected={beneficiaryIdSelected}
                accountingType={accountingType}
                setAccountingType={setAccountingType}
                selectedDocumentType={selectedDocumentType}
                setSelectedDocumentType={setSelectedDocumentType}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                getSearchOptions={getSearchOptions}
                loadingSupplierItems={loadingSupplierItems}
                setLoadingSupplierItems={setLoadingSupplierItems}
                getPaymentMethodOptions={getPaymentMethodOptions}
                comment={comment}
                setComment={setComment}
                reconciledData={reconciledData}
                setIsMarkAsReconciledModalVisible={
                  setIsMarkAsReconciledModalVisible
                }
                setReconciliationOption={setReconciliationOption}
                setStatusSelected={setStatusSelected}
                resetAccounting={resetAccounting}
                setSelectedMovement={setSelectedMovement}
                showSiigoIntermittencies={showSiigoIntermittencies}
                setDocumentTypes={setDocumentTypes}
                accountingAccountOptions={accountingAccountOptions}
                setAccountingAccountOptions={setAccountingAccountOptions}
                payanaReceipts={payanaReceipts}
                movementId={movementId}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
