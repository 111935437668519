import {
  Autocomplete,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { InputForm } from "./InputForm";
import { codeDocumentsType, organizationTypeItems } from "../utils/constants";
import { EmailInput, PhoneNumberInput } from "commons";
import { PHONE_NUMBER_VALIDATOR } from "utils";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import {
  createBillingCompany,
  getSearchSiigoCities,
  getSiigoCities,
  getUsersCompany,
  updateBOBilling,
} from "services";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { TrackJS } from "trackjs";
import { SearchIcon } from "assets";

export const ModalBilling = ({
  show = false,
  onHide = () => {},
  billing = null,
  companySelected = null,
  showUser = true,
  refreshSetCompanyBillings,
  isShowDetail,
}) => {
  const isCreateBilling = !billing?.id;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: billing,
  });
  const formValues = useWatch({ control });
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(isCreateBilling || isShowDetail);
  const [siigoCities, setSiigoCities] = useState([]);
  const [users, setUsers] = useState([]);

  const onSubmited = handleSubmit(async (data) => {
    try {
      setIsLoading(true);
      const formatBilling = {
        documentNumber: data.document_number,
        documentType: data.document_type,
        phone: data.phone,
        address: data?.address || "",
        email: data.email,
        contactName: data.contact_name,
        contactSurname: data.contact_surname,
        municipality: data.city?.state_code,
        organizationType: data.organization_type,
        city: data.city?.city_code,
        companyId: companySelected.id,
        accountantUserId: data.accountant_user_id,
        businessName: data.business_name,
      };

      let textToast = "Información de facturación actualizada";
      let respBilling;
      if (billing?.id) {
        respBilling = await updateBOBilling(formatBilling, billing.id);
        if (!respBilling) {
          return toast.error(
            "No se pudo actualizar la información de facturación",
            toastOptions
          );
        }
        textToast = "Información de facturación actualizada";
      } else {
        respBilling = await createBillingCompany(formatBilling);
        if (!respBilling) {
          return toast.error(
            "No se pudo actualizar la información de facturación",
            toastOptions
          );
        }
        textToast = "Información de facturación creada";
      }

      refreshSetCompanyBillings();
      toast.success(textToast, toastOptions);
      onHide();
    } catch (error) {
      TrackJS.console.error(error);
      toast.error(
        "No se pudo actualizar la información de facturación",
        toastOptions
      );
    } finally {
      setIsLoading(false);
      setIsEditable(false);
    }
  });

  const handlGetSiigoCities = (value) => {
    getSearchSiigoCities(value).then(setSiigoCities);
  };

  useEffect(() => {
    if (billing?.city) {
      getSiigoCities(billing.city).then((reps) => {
        setValue("city", reps);
        setValue("department", reps?.state_name);
      });
    }
  }, [billing]);

  useEffect(() => {
    getUsersCompany(companySelected.id).then((res) => {
      setUsers(res);
    });
  }, []);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body
        style={{ width: "100%", maxWidth: "400px", minWidth: "400px" }}
      >
        <p
          style={{
            margin: "5px",
            textAlign: "center",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 600,
          }}
        >
          Datos de facturacion
        </p>
        <Stack gap={1}>
          <InputForm
            label="Tipo de Organización"
            name="organization_type"
            errors={errors}
            required
            customInput={
              <Select
                name="organization_type"
                size="small"
                variant="outlined"
                displayEmpty={true}
                style={{ fontSize: "14px" }}
                value={formValues.organization_type}
                disabled={!isEditable}
                {...register("organization_type", {
                  required: true,
                  onChange: (e) =>
                    setValue("organization_type", e.target.value),
                })}
              >
                {organizationTypeItems.map((organization) => (
                  <MenuItem key={organization.value} value={organization.value}>
                    {organization.description}
                  </MenuItem>
                ))}
              </Select>
            }
          />
          <InputForm
            label="Razón Social"
            name="business_name"
            register={register}
            disabled={!isCreateBilling}
            errors={errors}
            required={isCreateBilling}
          />
          {((showUser && isCreateBilling) || billing?.accountant_user_id) && (
            <>
              <InputForm
                label="Usuario Contable"
                name="accountant_user_id"
                disabled={true}
                errors={errors}
                customInput={
                  <Select
                    name="accountant_user_id"
                    size="small"
                    variant="outlined"
                    displayEmpty={true}
                    style={{ fontSize: "14px" }}
                    value={formValues.accountant_user_id}
                    disabled={!isEditable}
                    renderValue={(option) => {
                      return (
                        users.find((user) => user.userId === option)?.name ||
                        option
                      );
                    }}
                    {...register("accountant_user_id", {
                      onChange: (e) => {
                        setValue("accountant_user_id", e.target.value);
                      },
                    })}
                  >
                    {users.map((user) => (
                      <MenuItem
                        key={user.userId}
                        value={user.userId}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          borderBottom: "1px solid #EAECF0",
                        }}
                      >
                        <snap
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            lineHeight: "20px",
                          }}
                        >
                          {user.name}
                        </snap>
                        <snap
                          style={{
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "18px",
                            color: "#667085",
                          }}
                        >
                          {user.email}
                        </snap>
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
            </>
          )}
          <InputForm
            label="Tipo de Documento"
            name="document_type"
            errors={errors}
            required
            customInput={
              <Select
                name="document_type"
                size="small"
                variant="outlined"
                displayEmpty={true}
                style={{ fontSize: "14px" }}
                value={formValues.document_type}
                disabled={!isEditable}
                {...register("document_type", {
                  required: true,
                  onChange: (e) => setValue("document_type", e.target.value),
                })}
              >
                {codeDocumentsType.map((documentType) => (
                  <MenuItem key={documentType.code} value={documentType.code}>
                    {documentType.descripcion}
                  </MenuItem>
                ))}
              </Select>
            }
          />
          <InputForm
            label="Número de identificación"
            name="document_number"
            errors={errors}
            register={register}
            disabled={!isEditable}
            required
          />
          <InputForm
            label="Ciudad"
            name="city"
            errors={errors}
            register={register}
            disabled={!isEditable}
            required
            customInput={
              <Autocomplete
                disabled={!isEditable}
                options={siigoCities}
                size="small"
                freeSolo
                filterOptions={(options, { inputValue }) => {
                  const inputValueLower = inputValue.toLowerCase();
                  return options.filter((option) =>
                    option.city_name.toLowerCase().includes(inputValueLower)
                  );
                }}
                getOptionLabel={(option) => {
                  return option?.city_name || "";
                }}
                onChange={(event, option) => {
                  setValue("city", option, { shouldValidate: true });
                  setValue("department", option?.state_name, {
                    shouldValidate: true,
                  });
                }}
                onInputChange={(event) => {
                  if (event?.target?.value?.length >= 3) {
                    handlGetSiigoCities(event.target.value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { fontSize: 14 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={"Buscar ciudad"}
                  />
                )}
                value={formValues.city}
              />
            }
          />
          <InputForm
            label="Departamento"
            name="department"
            errors={errors}
            register={register}
            disabled
            required
          />
          <InputForm
            label="Dirección"
            name="address"
            errors={errors}
            register={register}
            disabled={!isEditable}
          />
          <InputForm
            label="Teléfono"
            name="phone"
            errors={errors}
            required
            customInput={
              <PhoneNumberInput
                phone={formValues.phone}
                disabled={!isEditable}
                setPhone={(value) => {
                  setValue("phone", value, { shouldValidate: true });
                }}
                {...register("phone", PHONE_NUMBER_VALIDATOR())}
              />
            }
          />
          <InputForm
            label="Email"
            name="email"
            errors={errors}
            required
            customInput={
              <EmailInput
                value={formValues.email}
                setValue={(value) => {
                  setValue("email", value, { shouldValidate: true });
                }}
                register={register}
                required={true}
                disabled={!isEditable}
              />
            }
          />
          <InputForm
            label="Contacto - Nombre"
            name="contact_name"
            errors={errors}
            register={register}
            disabled={!isEditable}
            required
          />
          <InputForm
            label="Contacto - Apellido"
            name="contact_surname"
            errors={errors}
            register={register}
            disabled={!isEditable}
            required
          />
        </Stack>
        {(isShowDetail || isCreateBilling) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "20px",
              marginTop: "24px",
            }}
          >
            <SecondaryButton
              isDisable={isLoading}
              text="Cancelar"
              action={onHide}
            />
            <PrimaryButton
              loading={isLoading}
              text={isEditable ? "Guardar" : "Editar"}
              action={
                isEditable ? () => onSubmited() : () => setIsEditable(true)
              }
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
