import React, { useContext } from "react";
import { UserContext } from "contexts";
import { useState } from "react";

export const useSubscription = ({ type }) => {
  const { currentUser, currentCompany, getMe } = useContext(UserContext);
  const [showModalSubscription, setShowModalSubscription] = useState(false);
  const [callbackStore, setCallbackStore] = useState(null);
  const [isPayanaInvoice, setIsPayanaInvoice] = useState(false);

  const { subscriptions } = currentUser;

  const subscription = subscriptions?.find((sub) => sub.featureType === type);
  const hasSubscriptionActive = currentCompany?.subscription || false;

  const handleActionSubscription = (callback, onClose = () => {}) => {
    if (
      hasSubscriptionActive &&
      (!subscription?.status || subscription?.status === "inactive") &&
      !isPayanaInvoice
    ) {
      setShowModalSubscription(true);
      setCallbackStore(() => callback);
      onClose();
      return;
    }

    return callback();
  };

  const showIcon =
    hasSubscriptionActive &&
    (!subscription?.status || subscription?.status === "inactive");

  return {
    companySubscriptions: subscriptions,
    hasSubscriptionActive,
    type,
    showIcon,
    subscription,
    showModalSubscription,
    handleActionSubscription,
    hadleOpenModalSubscription: () => setShowModalSubscription(true),
    hadleCloseModalSubscription: () => setShowModalSubscription(false),
    getMe: () => getMe(),
    callback: callbackStore,
    handleSetIsPayanaInvoice: (value) => setIsPayanaInvoice(value),
    isPayanaInvoice,
  };
};
