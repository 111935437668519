import React, { useEffect } from "react";
import { MenuItem, Select, Typography } from "@mui/material";
import { ThirdButton } from "commons/buttons";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { PlusIcon } from "assets";
import { saveInLocalStorage } from "utils";

export const Banks = ({
  banks,
  setIsBankExtractModalVisible,
  selectedBank,
  setSelectedBank,
  resetAccounting,
  setSelectedMovement,
}) => {
  useEffect(() => {
    saveInLocalStorage("reconciliationsBankId", banks.at(0).id);
  }, [banks]);

  const BankLabel = ({ bank }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          alignItems: banks.length === 1 ? "flex-end" : "center",
          justifyContent: "center",
        }}
      >
        {bank.logo && (
          <img
            style={{
              height: "23px",
              width: "23px",
              marginRight: "2px",
            }}
            src={bank.logo}
            alt="bankLogo"
          />
        )}

        <Typography
          style={{
            fontSize: "14px",
            color: "#344054",
            textTransform: "capitalize",
            width: "100%",
            fontWeight: banks.length === 1 ? "600" : "500",
          }}
        >
          {bank.name.toLowerCase()} - {bank.bank_alias}
        </Typography>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {banks.length === 1 ? (
        <div
          style={{
            height: "40px",
            display: "flex",
          }}
        >
          <BankLabel bank={banks.at(0)} />
        </div>
      ) : (
        <Select
          onChange={(e) => {
            const bankSelected = banks.find(
              (bank) => bank.id === e.target.value
            );
            saveInLocalStorage("reconciliationsBankId", bankSelected.id);
            setSelectedBank(bankSelected);
            setSelectedMovement({});
            resetAccounting();
          }}
          style={{ fontSize: "14px", gap: "8px", display: "flex" }}
          size="small"
          value={selectedBank.id || ""}
          required={true}
          displayEmpty
        >
          {banks.map((bank) => {
            return (
              <MenuItem
                key={bank.id}
                className="bank-select"
                style={{ fontSize: "14px" }}
                value={bank.id}
              >
                <BankLabel bank={bank} />
              </MenuItem>
            );
          })}
        </Select>
      )}
      <ThirdButton
        text="Agregar banco"
        action={setIsBankExtractModalVisible}
        width="fit-content"
        icon={<PlusIcon stroke="#6938EF" />}
        style={{
          color: "#6938EF",
          fontSize: "14px",
          fontWeight: "500",
        }}
        contentStyle={{ justifyContent: "center" }}
      />
    </div>
  );
};
