import React from "react";
import banner from "./seminar-banner.png";

export const SeminarBannerImage = ({ width = "100%", height = 145 }) => (
  <img
    src={banner}
    width={width}
    height={height}
    alt="Referidos"
    style={{ position: "relative" }}
  />
);
