import React, { useContext } from "react";

import styles from "../styles.module.css";
import { useMediaQuery } from "react-responsive";
import { ReconciliationsContext } from "contexts";

export const ReconciliationTabs = ({
  optionSelected,
  setOptionSelected,
  setStatusSelected,
  setSelectedMovement,
  setTabSelected,
  setAccountingType,
  setSelectedCostCenter,
}) => {
  const { reconciliationsScreen } = useContext(ReconciliationsContext);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const handleChangeTab = (optionSelected) => {
    setOptionSelected(optionSelected);
    setStatusSelected(optionSelected);
    setSelectedMovement({});
    setTabSelected("register");
    setAccountingType("paymentRecord");
    reconciliationsScreen?.beneficiaryFilter?.setter({});
    setSelectedCostCenter(null);
  };

  return (
    <div
      className={isMobile ? styles.tabsContainerMobile : styles.tabsContainer}
    >
      <button
        className={`${styles.tabBase} ${
          optionSelected === "not_reconciled" && styles.tabSelected
        } `}
        onClick={() => handleChangeTab("not_reconciled")}
      >
        <label
          className={`${styles.labelBase} ${
            optionSelected === "not_reconciled" && styles.labelSelected
          }`}
        >
          No conciliados
        </label>
      </button>
      <button
        className={`${styles.tabBase} ${
          optionSelected === "reconciled" && styles.tabSelected
        }`}
        onClick={() => handleChangeTab("reconciled")}
      >
        <label
          className={`${styles.labelBase} ${
            optionSelected === "reconciled" && styles.labelSelected
          }`}
        >
          Conciliados
        </label>
      </button>
    </div>
  );
};
