import React, { useContext, useEffect, useRef, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { SecondaryButton } from "commons/buttons";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { SearchIcon } from "assets";
import { AccountingTabs } from "./AccountingTabs";
import { objectIsEmpty } from "utils/objectUtils";
import { styles } from "../styles";
import { ReconciliationsContext, UserContext } from "contexts";
import { formatRegisterDate } from "../utils";
import { useAssistedExpenses } from "hooks";
import {
  createAccountingReceipt,
  createCashReceipt,
  createPaymentRecord,
} from "services";
import { formatDate } from "commons/modals/ExpandAssistedExpenses/utils";
import { TableSkeleton } from "commons";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { ReconciledData } from "./ReconciledData";
import { AccountingRegisterEmptyState } from "./AccountingRegisterEmptyState";
import { AccountingRadioGroup } from "./AccountingRadioGroup";
import { AccountingSupplierForm } from "./AccountingSupplierForm";
import { AccountingReconcileEmptyState } from "./AccountingReconcileEmptyState";

export const Accounting = ({
  accountingOption,
  setAccountingOption,
  selectedMovement,
  searchOptions,
  handleSearchChange,
  prevItems,
  costCenters,
  selectedCostCenter,
  setSelectedCostCenter,
  documentTypes,
  selectedDocumentType,
  setSelectedDocumentType,
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  beneficiaryIdSelected,
  accountingType,
  setAccountingType,
  getSearchOptions,
  loadingSupplierItems,
  getPaymentMethodOptions,
  comment,
  setComment,
  reconciledData,
  setIsMarkAsReconciledModalVisible,
  setReconciliationOption,
  setStatusSelected,
  resetAccounting,
  setSelectedMovement,
  showSiigoIntermittencies,
  setDocumentTypes,
  setLoadingSupplierItems,
  accountingAccountOptions,
  setAccountingAccountOptions,
  payanaReceipts,
  movementId,
}) => {
  const { reconciliationsScreen, selectedBank } = useContext(
    ReconciliationsContext
  );
  const { currentUser } = useContext(UserContext);
  const { setItems } = useAssistedExpenses({
    isAssistedEgress: false,
    movementAmount:
      selectedMovement.amount < 0
        ? selectedMovement.amount * -1
        : selectedMovement.amount,
  });
  const textareaRef = useRef(null);
  const [advanceAmount, setAdvanceAmount] = useState(
    selectedMovement.amount < 0
      ? selectedMovement.amount * -1
      : selectedMovement.amount
  );
  const [costCenterMandatory, setCostCenterMandatory] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [createRequestInProcess, setCreateRequestInProcess] = useState(false);

  const { register, handleSubmit, formState, control, reset, setValue } =
    useForm({
      defaultValues: {
        documentType: selectedDocumentType,
        paymentMethod: selectedPaymentMethod,
        costCenter: selectedCostCenter,
        comments: comment,
        ...(accountingType === "accountingReceipt" && { amount: 0 }),
        ...(accountingType === "accountingReceipt" && {
          accountingAccount: "",
        }),
      },
      mode: "onBlur",
    });

  const formValues = useWatch({ control });

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Resetea la altura
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Ajusta la altura
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [comment]);

  useEffect(() => {
    setItems(prevItems);
  }, [prevItems]);

  const handleRegister = {
    paymentRecord: () => {
      return createPaymentRecord({
        items:
          selectedRows.length > 0
            ? selectedRows.map((item) => ({
                ...item,
                Value: item.amountRegister,
                amountRegister: item.amountRegister,
                documentName: item.DuePrefix + "-" + item.DueConsecutive,
              }))
            : [],
        documentType: selectedDocumentType,
        type: selectedRows.length === 0 ? "Anticipo" : "Abono a deuda",
        costCenter: selectedCostCenter,
        paymentMethod: {
          ACPaymentMeanID: selectedPaymentMethod?.ACPaymentMeanID,
          Name: selectedPaymentMethod?.Name,
        },
        paidAt: formatDate(
          selectedMovement.issue_date || selectedMovement.createdAt
        ),
        comments: comment,
        provider: reconciliationsScreen.beneficiaryFilter.value,
        amountTotal:
          selectedMovement.amount < 0
            ? selectedMovement.amount * -1
            : selectedMovement.amount,
        reconciliationMovementId:
          payanaReceipts.length > 0 ? movementId : selectedMovement.id,
        ...(payanaReceipts.length > 0 && {
          receiptId: selectedMovement.id,
        }),
      });
    },
    cashRegisterReceipt: () => {
      return createCashReceipt({
        items:
          selectedRows.length > 0
            ? selectedRows.map((item) => ({
                due: {
                  prefix: item.DuePrefix,
                  consecutive: item.DueConsecutive,
                  quote: item.DueQuote,
                  date: formatRegisterDate(item.DueDateDisplay),
                },
                value: item.amountRegister,
                amountRegister: item.amountRegister,
                documentName: item.DuePrefix + "-" + item.DueConsecutive,
              }))
            : [],
        documentType: selectedDocumentType,
        receiptType:
          selectedRows.length === 0 ? "AdvancePayment" : "DebtPayment",
        costCenter: selectedCostCenter,
        paymentMethod: {
          ACPaymentMeanID: selectedPaymentMethod?.ACPaymentMeanID,
          Name: selectedPaymentMethod?.Name,
        },
        issueDate: formatRegisterDate(
          selectedMovement.issue_date || selectedMovement.createdAt
        ),
        comments: comment,
        provider: reconciliationsScreen.beneficiaryFilter.value,
        amountTotal:
          selectedMovement.amount < 0
            ? selectedMovement.amount * -1
            : selectedMovement.amount,
        reconciliationMovementId:
          payanaReceipts.length > 0 ? movementId : selectedMovement.id,
        ...(payanaReceipts.length > 0 && {
          receiptId: selectedMovement.id,
        }),
      });
    },
    accountingReceipt: () => {
      return createAccountingReceipt({
        items: [
          {
            account: {
              code: selectedBank.erp_external_account_code.toString(),
              movement: selectedMovement.amount > 0 ? "Credit" : "Debit",
            },

            customer: {
              identification:
                reconciliationsScreen?.beneficiaryFilter.value.Identification.toString(),
              branch_office: 0,
            },
            value:
              selectedMovement.amount < 0
                ? selectedMovement.amount * -1
                : selectedMovement.amount,
            cost_center: selectedCostCenter,
          },
          {
            account: {
              code: formValues.accountingAccount.toString(),
              movement: selectedMovement.amount > 0 ? "Debit" : "Credit",
            },
            customer: {
              identification:
                reconciliationsScreen?.beneficiaryFilter.value.Identification.toString(),
              branch_office: 0,
            },
            value:
              selectedMovement.amount < 0
                ? selectedMovement.amount * -1
                : selectedMovement.amount,
            cost_center: selectedCostCenter,
          },
        ],
        documentType: {
          ...selectedDocumentType,
          id: selectedDocumentType.ERPDocumentTypeID,
        },
        issueDate: formatRegisterDate(
          selectedMovement.issue_date || selectedMovement.createdAt
        ),
        comments: comment,
        provider: reconciliationsScreen.beneficiaryFilter.value,
        amountTotal:
          selectedMovement.amount < 0
            ? selectedMovement.amount * -1
            : selectedMovement.amount,
        reconciliationMovementId:
          payanaReceipts.length > 0 ? movementId : selectedMovement.id,
        ...(payanaReceipts.length > 0 && {
          receiptId: selectedMovement.id,
        }),
      });
    },
  };

  const submitForm = handleSubmit(async (formValues) => {
    setCreateRequestInProcess(true);
    try {
      const res = await handleRegister[accountingType]();

      if (res?.response && res.response.status !== 200) {
        throw new Error("Error al registrar");
      }

      setCreateRequestInProcess(false);
      toast.success("¡Registro realizado con éxito!", toastOptions);
      resetAccounting();
      setSelectedMovement({});
      setReconciliationOption("reconciled");
      setStatusSelected("reconciled");
      amplitude.logEvent("END_CREATE_ERP_RECEIPT_FLOW", {
        user_id: currentUser.id,
      });
    } catch (error) {
      setCreateRequestInProcess(false);
      toast.error(
        "Ocurrió un error, por favor intente nuevamente.",
        toastOptions
      );
    }
  });

  const changeAdvanceAmount = (amount) => {
    setAdvanceAmount(amount < 0 ? amount * -1 : amount);
  };

  useEffect(() => {
    reset({
      documentType: selectedDocumentType,
      paymentMethod: selectedPaymentMethod,
      costCenter: selectedCostCenter,
      comments: comment,
      ...(accountingType === "accountingReceipt" && { amount: 0 }),
      ...(accountingType === "accountingReceipt" && {
        accountingAccount: "",
      }),
    });
    setAccountingType(
      selectedMovement.amount > 0 ? "cashRegisterReceipt" : "paymentRecord"
    );
    changeAdvanceAmount(selectedMovement.amount);
  }, [selectedMovement]);

  return (
    <div style={styles.accountingContainer}>
      <div style={styles.accountingColumn}>
        <div style={styles.accountingTitleContainer}>
          <Typography sx={styles.accountingTitle} noWrap>
            Contabilidad
          </Typography>
        </div>
        {selectedMovement.status === "reconciled" ||
        selectedMovement.status === "reconciled_outside" ||
        selectedMovement.accounting_receipt_id ? (
          <ReconciledData
            reconciledData={reconciledData}
            advanceAmount={advanceAmount}
            textareaRef={textareaRef}
            status={selectedMovement.status}
          />
        ) : (
          <>
            <AccountingTabs
              tabSelected={accountingOption}
              setTabSelected={setAccountingOption}
            />
            {accountingOption === "register" ? (
              objectIsEmpty(selectedMovement) ? (
                <AccountingRegisterEmptyState
                  accountingType={accountingType}
                  setAccountingType={setAccountingType}
                  selectedMovement={selectedMovement}
                />
              ) : (
                <>
                  <AccountingRadioGroup
                    accountingType={accountingType}
                    setAccountingType={setAccountingType}
                    selectedMovement={selectedMovement}
                    setDocumentTypes={setDocumentTypes}
                    setLoadingSupplierItems={setLoadingSupplierItems}
                    setAccountingAccountOptions={setAccountingAccountOptions}
                  />
                  <div style={styles.accountingItemsContainer}>
                    <Typography sx={styles.supplierTitle}>
                      Nombre del tercero
                    </Typography>
                    <Autocomplete
                      disabled={createRequestInProcess}
                      options={searchOptions}
                      groupBy={(option) => option.type}
                      size="small"
                      sx={{ width: "100%" }}
                      freeSolo
                      getOptionLabel={(option) => {
                        return option?.FullName || "";
                      }}
                      onInputChange={(event) => {
                        if (event?.target?.value?.length >= 3) {
                          getSearchOptions(event.target.value);
                        }
                      }}
                      onChange={(event, option) => {
                        if (option?.AccountID) {
                          handleSearchChange(option);
                        } else {
                          handleSearchChange();
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          placeholder={"Buscar tercero"}
                        />
                      )}
                      value={
                        reconciliationsScreen?.beneficiaryFilter.value || {
                          FullName: "",
                        }
                      }
                    />
                    {showSiigoIntermittencies && (
                      <Typography
                        sx={{ color: "rgba(240, 68, 56, 1)", fontSize: 12 }}
                      >
                        Siigo se encuentra con intermitencias, por favor intenta
                        nuevamente en un minuto.
                      </Typography>
                    )}
                    {loadingSupplierItems ? (
                      <div
                        className="table-skeleton-page-initial-pageLoading"
                        style={{ width: "100%", marginBottom: "16px" }}
                      >
                        <TableSkeleton />
                      </div>
                    ) : (
                      !objectIsEmpty(
                        reconciliationsScreen.beneficiaryFilter.value
                      ) && (
                        <AccountingSupplierForm
                          submitForm={submitForm}
                          createRequestInProcess={createRequestInProcess}
                          register={register}
                          selectedDocumentType={selectedDocumentType}
                          setSelectedDocumentType={setSelectedDocumentType}
                          setCostCenterMandatory={setCostCenterMandatory}
                          documentTypes={documentTypes}
                          formState={formState}
                          paymentMethods={paymentMethods}
                          setSelectedPaymentMethod={setSelectedPaymentMethod}
                          setValue={setValue}
                          getPaymentMethodOptions={getPaymentMethodOptions}
                          selectedPaymentMethod={selectedPaymentMethod}
                          costCenters={costCenters}
                          setSelectedCostCenter={setSelectedCostCenter}
                          costCenterMandatory={costCenterMandatory}
                          formValues={formValues}
                          prevItems={prevItems}
                          textareaRef={textareaRef}
                          comment={comment}
                          setComment={setComment}
                          advanceAmount={advanceAmount}
                          setIsMarkAsReconciledModalVisible={
                            setIsMarkAsReconciledModalVisible
                          }
                          setAdvanceAmount={setAdvanceAmount}
                          selectedMovement={selectedMovement}
                          changeAdvanceAmount={changeAdvanceAmount}
                          setSelectedRows={setSelectedRows}
                          accountingType={accountingType}
                          accountingAccountOptions={accountingAccountOptions}
                        />
                      )
                    )}
                  </div>
                </>
              )
            ) : (
              <AccountingReconcileEmptyState />
            )}
          </>
        )}
      </div>
      {!objectIsEmpty(selectedMovement) &&
        objectIsEmpty(reconciliationsScreen.beneficiaryFilter.value) &&
        selectedMovement.status !== "reconciled" &&
        selectedMovement.status !== "reconciled_outside" &&
        !selectedMovement.accounting_receipt_id &&
        accountingOption !== "reconcile" && (
          <div
            style={{
              ...styles.formButtonsContainer,
              marginBottom: "8px",
            }}
          >
            <SecondaryButton
              text="Marcar como conciliado"
              action={setIsMarkAsReconciledModalVisible}
              width="182px"
              isDisable={createRequestInProcess}
            />
          </div>
        )}
    </div>
  );
};
