import { requestContainer } from "../requestContainer";

export async function getFeatures() {
  return requestContainer({ method: "GET", url: `/features` });
}

export async function createSubcription(data) {
  return requestContainer({ method: "POST", url: `/subscriptions`, data });
}

export async function getInvoicesSubscriptionPending() {
  return requestContainer({
    method: "GET",
    url: `/invoices/subscriptions/pending`,
  });
}

export async function getBOCompanyFeatures(companyId) {
  return requestContainer({
    method: "GET",
    url: `/subscriptions/company/${companyId}`,
  });
}

export async function updateBOSubscriptionCompany(data) {
  return requestContainer({
    method: "POST",
    url: `/subscriptions/company`,
    data,
  });
}

export async function getBillingCompany(companyId) {
  return requestContainer({
    method: "GET",
    url: `/billing/company/${companyId}`,
  });
}

export async function getBillinInformation(billingId) {
  return requestContainer({
    method: "GET",
    url: `/billing/${billingId}`,
  });
}

export async function updateBOBilling(billing, billingId) {
  return requestContainer({
    method: "PUT",
    url: `/billing/${billingId}`,
    data: billing,
  });
}
