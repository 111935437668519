import React, { useContext } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { styles } from "../styles";
import { Typography } from "@mui/material";
import { getWppLink, openInNewTab } from "utils";
import { PrimaryButton } from "commons/buttons";
import { CloseIcon, SeminarBannerImage } from "assets";
import { UserContext } from "contexts";
import { SEMINAR_LINK } from "constants";

export const SeminarBanner = ({ onClose }) => {
  const { currentUser } = useContext(UserContext);

  return (
    <div style={styles.referralContainer}>
      <SeminarBannerImage height="145px" />
      <div style={styles.referralContent}>
        <div style={styles.referralColumn}>
          <div style={styles.rowSpaceBetween}>
            <Typography sx={styles.referralTitle}>
              Seminario de Nuevas funcionalidades
            </Typography>
            <button
              style={{
                cursor: "pointer",
                marginTop: "-16px",
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={onClose}
            >
              <CloseIcon color={"#FFFFFF"} />
            </button>
          </div>
          <Typography sx={styles.referralInfo}>
            Miércoles 16 de Octubre | 9hs o 16hs
          </Typography>
          <PrimaryButton
            text="Regístrate aquí"
            action={() => {
              amplitude.logEvent("CLICK_SEMINAR_BANNER", {
                user_id: currentUser.id,
              });
              openInNewTab(SEMINAR_LINK);
            }}
            width="133px"
            style={styles.seminarButton}
          />
        </div>
      </div>
    </div>
  );
};
