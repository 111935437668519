import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { styles } from "../styles";
import { BankIcon, ImportIcon, SiigoLogo, SyncIcon } from "../../../assets";
import { WithoutBorderButton } from "commons/buttons";
import ReconciliationsButtonStepEmptyState from "./ButtonStepEmptyState";

export const ReconciliationsEmptyState = ({
  setIsSiigoModalVisible,
  setIsBankExtractModalVisible,
  syncCS,
}) => {
  return (
    <Box sx={styles.emptyStateBox}>
      <Stack spacing={2} p={4} sx={styles.emptyStateStack}>
        <Typography sx={{ ...styles.emptyStateTitle, marginBottom: "4px" }}>
          <strong>Conciliaciones Simplificadas:</strong> Sincroniza con Siigo e
          importa tu extracto bancario
        </Typography>
        <Typography sx={styles.emptyStateSubtitle}>
          Para comenzar a conciliar tus transacciones y registrar tus
          movimientos en tu contabilidad, primero sincroniza tu cuenta con Siigo
          y luego importa el extracto de tu banco.
        </Typography>
        <Stack
          orientation="vertical"
          size="large"
          spacing={2}
          style={{ maxWidth: "638px", marginTop: "64px" }}
        >
          <ReconciliationsButtonStepEmptyState
            icon={
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SiigoLogo />
              </div>
            }
            title={"Sincroniza con Siigo"}
            text={
              "Importaremos tus centros de costos, terceros y cuentas contables. "
            }
            action={() => setIsSiigoModalVisible(true)}
            disabled={syncCS?.siigo}
            completed={syncCS?.siigo}
            buttonStep={1}
            actionButton={
              <WithoutBorderButton
                text="Sincronizar"
                action={() => setIsSiigoModalVisible(true)}
                width="112px"
                contentStyle={{
                  marginLeft: "-6px",
                  color: syncCS.siigo ? "#D0D5DD" : "#5925DC",
                }}
                endIcon={
                  <SyncIcon color={syncCS.siigo ? "#D0D5DD" : "#5925DC"} />
                }
                isDisable={syncCS?.siigo}
              />
            }
          />
          <ReconciliationsButtonStepEmptyState
            icon={<BankIcon color="#6938EF" width="23" height="23" />}
            title={"Importa tu extracto bancario"}
            text={
              "Para registrar tus movimientos, debes importar el extracto de tu cuenta bancaria. (Puedes importar cualquier rango de fecha)."
            }
            action={() => setIsBankExtractModalVisible(true)}
            disabled={!syncCS?.siigo}
            completed={false}
            buttonStep={2}
            actionButton={
              <WithoutBorderButton
                text="Importar"
                action={() => setIsBankExtractModalVisible(true)}
                width="112px"
                contentStyle={{
                  marginLeft: "-6px",
                  color: !syncCS.siigo ? "#D0D5DD" : "#5925DC",
                }}
                endIcon={
                  <ImportIcon color={!syncCS.siigo ? "#D0D5DD" : "#5925DC"} />
                }
                isDisable={!syncCS?.siigo}
              />
            }
          />
        </Stack>
      </Stack>
    </Box>
  );
};
