const cardInformationListText = {
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  margin: "0px",
  color: "#344054",
};

export const SUBSCRIPTION_TYPE = {
  TREASURY: "treasury",
  EXPENSES: "expenses",
  CAUSATION: "causation",
};

export const FEATURE_TIME = {
  MONTHLY: 1,
  ANNUAL: 12,
};

export const LIST_CARD_INFORMATION = {
  [SUBSCRIPTION_TYPE.TREASURY]: [
    {
      text: (
        <p style={cardInformationListText}>
          Acceso a solución de proveedores, nómina y recaudo
        </p>
      ),
    },
    {
      text: (
        <p style={cardInformationListText}>
          Pagos vía PSE sin comisión bancaria ni ciclos ACH
        </p>
      ),
    },
    {
      text: (
        <p style={cardInformationListText}>
          Notificación de soportes de pago vía Whatsapp
        </p>
      ),
    },
    {
      text: (
        <p style={cardInformationListText}>
          Control, visibilidad y reportes de tus cuentas a pagar y cobrar
        </p>
      ),
    },
    {
      text: <p style={cardInformationListText}>Inscripción de proveedores</p>,
    },
    {
      text: (
        <p style={cardInformationListText}>
          Descargables y buscadores de documentos
        </p>
      ),
    },
  ],
  [SUBSCRIPTION_TYPE.EXPENSES]: [
    {
      text: (
        <p style={cardInformationListText}>
          Registra tus pagos a proveedores en Siigo.
        </p>
      ),
    },
  ],
  [SUBSCRIPTION_TYPE.CAUSATION]: [
    {
      text: (
        <p style={cardInformationListText}>
          Importa <strong>automáticamente</strong> tus documentos desde la DIAN.
        </p>
      ),
    },
    {
      text: (
        <p style={cardInformationListText}>
          Causa tus facturas y documentos soporte en Siigo{" "}
          <strong>un 75% más rápido.</strong>
        </p>
      ),
    },
  ],
};

export const TITLE_FEATURE = {
  [FEATURE_TIME.MONTHLY]: "Plan mensual",
  [FEATURE_TIME.ANNUAL]: "Plan anual",
};

export const PRICE_FEATURE = {
  [SUBSCRIPTION_TYPE.TREASURY]: {
    [FEATURE_TIME.MONTHLY]: "120.000 COP",
    [FEATURE_TIME.ANNUAL]: "1.224.000 COP (102.000 COP/mes)",
  },
  [SUBSCRIPTION_TYPE.EXPENSES]: {
    [FEATURE_TIME.MONTHLY]: "50.000 COP",
    [FEATURE_TIME.ANNUAL]: "510.000 COP (42.500 COP/mes)",
  },
  [SUBSCRIPTION_TYPE.CAUSATION]: {
    [FEATURE_TIME.MONTHLY]: "100.000 COP",
    [FEATURE_TIME.ANNUAL]: "1.020.000 COP (85.000 COP/mes)",
  },
};

export const TITLE = {
  [SUBSCRIPTION_TYPE.TREASURY]: "¡Suscríbete a la solución de Tesorería PRO!",
  [SUBSCRIPTION_TYPE.EXPENSES]:
    "¡Suscríbete a la solución de Egresos en Siigo!",
  [SUBSCRIPTION_TYPE.CAUSATION]: "¡Suscríbete a la solución de Causación!",
};

export const DISCOUNT = {
  [FEATURE_TIME.MONTHLY]: "",
  [FEATURE_TIME.ANNUAL]: "Mejor oferta 15% off",
};

export const MONTHLY_TREASURY = "Tesoreria Mensual";
export const ANNUAL_TREASURY = "Tesoreria Anual";
