import React from "react";
import { Stack, Button, Typography } from "@mui/material";
import { styles } from "../styles";
import { Pills } from "../../../commons/components/Pills";
import { EmptyCheckIcon } from "../../../assets";

export default function ReconciliationsButtonStepEmptyState({
  disabled,
  completed,
  title,
  text,
  icon,
  action = () => {},
  actionButton,
}) {
  return (
    <Button
      key="zero"
      id="empty_button_integration_dian"
      startIcon={icon}
      sx={{
        ...styles.emptyStateBtnInGroup,
        opacity: disabled ? "0.6" : "",
        "&:disabled": { borderColor: "none", justifyContent: "space-between" },
      }}
      onClick={action}
      disabled={disabled}
    >
      <Stack ml={2} gap={1} style={{ width: "100%" }}>
        <Stack
          direction={"row"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} spacing={1}>
            <Typography sx={styles.buttonStepEmptyStateTitle}>
              {title}
            </Typography>
          </Stack>
          <Pills
            bgColor={completed ? "#ECFDF3" : "#EBE9FE"}
            textColor={completed ? "#054F31" : ""}
            text={completed ? "Completado" : "Pendiente"}
            icon={
              completed ? (
                <EmptyCheckIcon bgColor={"#ECFDF3"} color={"#054F31"} />
              ) : (
                ""
              )
            }
          />
        </Stack>
        <Typography style={styles.buttonStepEmptyStateText}>{text}</Typography>
        {actionButton}
      </Stack>
    </Button>
  );
}
