import { CircularProgress } from "@mui/material";

export const PrimaryButton = ({
  text,
  action,
  isDisable = false,
  height = "40px",
  width = "100px",
  fontSize = "14px",
  type = "button",
  loading = false,
  icon = null,
  style = {},
}) => {
  const styles = {
    backgroundColor: isDisable ? "#a181fb" : "#6938EF",
    borderRadius: "8px",
    height: height,
    color: "#FFFFFF",
    fontSize: fontSize,
    fontWeight: "500",
    lineHeight: "20px",
    width: width,
    border: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#4f29b6",
    },
    textWrap: "nowrap",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    ...style,
  };

  return (
    <button
      type={type}
      onClick={action}
      disabled={isDisable}
      style={styles}
      className={
        isDisable ? "payana-primary-button-disabled" : "payana-primary-button"
      }
    >
      {icon}
      {!loading && text}
      {loading && <CircularProgress size={22} color="inherit" />}
    </button>
  );
};
