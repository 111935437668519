import { format } from "date-fns";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const createReconciliationsXLSX = (response) => {
  const { bankMovementsExport } = response;

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let sheets = {};
  const keys = Object.keys(bankMovementsExport);

  keys.map((key) => {
    sheets[key] = XLSX.utils.json_to_sheet(bankMovementsExport[key]);
  });

  const wb = {
    Sheets: sheets,
    SheetNames: Object.keys(bankMovementsExport),
  };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const data = new Blob([excelBuffer], { type: fileType });

  const now = new Date();

  FileSaver.saveAs(
    data,
    `Conciliación-${format(now, "dd.MM.yyyy")}-Payana` + fileExtension
  );
};

export default createReconciliationsXLSX;
