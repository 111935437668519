import React, { useContext, useState } from "react";
import * as ReconciliationsService from "../../../services/api/reconciliations/Reconciliations.service";
import { Typography } from "@mui/material";
import { SecondaryButton, ThirdButton } from "commons/buttons";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  PlusIcon,
  RightArrowIcon,
} from "assets";
import { styles } from "../styles";
import { ReconciliationTabs } from "./ReconciliationTabs";
import { BankMovementItem } from "./BankMovementItem";
import { objectIsEmpty } from "utils/objectUtils";
import { PayanaReceiptItem } from "./PayanaReceiptItem";
import { BankMovementsFilters } from "./BankMovementsFilters";
import { MovementsEmptyState } from "./MovementsEmptyState";
import { TableSkeleton } from "commons";
import { getFromLocalStorage, saveInLocalStorage } from "utils";
import { PaymentsContext } from "contexts";

export const BankMovements = ({
  bankMovements,
  selectedMovement,
  setSelectedMovement,
  setPageIndex,
  reconciliationOption,
  setReconciliationOption,
  setStatusSelected,
  date,
  setDate,
  selectedDate,
  setSelectedDate,
  type,
  setType,
  setTabSelected,
  setAccountingType,
  reconciliationsCsvButton,
  setPerPageSelected,
  setSelectedCostCenter,
  payanaReceipts,
  setPayanaReceipts,
  resetAccounting,
  setReconciledData,
  amountSignSelected,
  setAmountSignSelected,
  totalPages,
  pageIndex,
  pageLoading,
  setPageLoading,
  setMovementId,
  selectedBank,
}) => {
  const { allBanks } = useContext(PaymentsContext);
  const [movementAlias, setMovementAlias] = useState("");

  const handleSelectMovement = (movement) => {
    resetAccounting();

    if (
      movement.status === "reconciled" ||
      movement.status === "reconciled_outside"
    ) {
      ReconciliationsService.getBankMovementData(movement.id).then((res) => {
        if (res.length > 0) {
          setReconciledData(res.at(0));
        }
      });
    }

    if (movement.accounting_receipt_id) {
      ReconciliationsService.getPayanaBankMovementData(
        movement.accounting_receipt_id
      ).then((res) => {
        if (res.length > 0) {
          setReconciledData(res.at(0));
        }
      });
    }

    if (movement.is_from_payana) {
      setPageLoading(true);
      ReconciliationsService.getPayanaMovementReceipts(
        movement.amount,
        movement.issue_date
      ).then((res) => {
        setPayanaReceipts(res?.transactions);
        setMovementAlias(movement.description);
        setMovementId(movement.id);
        setSelectedMovement({});
        setPageLoading(false);
      });
    } else {
      setSelectedMovement(movement);
    }
  };

  return (
    <div style={styles.bankMovementsContainer}>
      <div style={styles.bankMovementsTitleContainer}>
        <Typography sx={styles.bankMovementsTitle} noWrap>
          Movimientos de dinero
        </Typography>
        {/* {objectIsEmpty(payanaReceipts) && (
          <ThirdButton
            text={"Cargar movimientos"}
            width="fit-content"
            action={() => {
              if (
                allBanks.find((bank) => bank.id === selectedBank.bank_id)
                  .name === "BANCOLOMBIA"
              ) {
              } else {
                saveInLocalStorage("isNewBank", false);
                reconciliationsCsvButton.current.click();
              }
            }}
            icon={<PlusIcon stroke="#5925DC" />}
            style={{ fontSize: "14px" }}
          />
        )} */}
      </div>
      {objectIsEmpty(payanaReceipts) ? (
        <>
          <ReconciliationTabs
            optionSelected={reconciliationOption}
            setOptionSelected={setReconciliationOption}
            setStatusSelected={setStatusSelected}
            setSelectedMovement={setSelectedMovement}
            setTabSelected={setTabSelected}
            setAccountingType={setAccountingType}
            setSelectedCostCenter={setSelectedCostCenter}
          />
          {!pageLoading &&
            (!objectIsEmpty(bankMovements) ||
              (objectIsEmpty(bankMovements) &&
                amountSignSelected !== "all")) && (
              <BankMovementsFilters
                date={date}
                setDate={setDate}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setPageIndex={setPageIndex}
                type={type}
                setType={setType}
                amountSignSelected={amountSignSelected}
                setAmountSignSelected={setAmountSignSelected}
                setPerPageSelected={setPerPageSelected}
              />
            )}
        </>
      ) : (
        <>
          <div
            style={styles.backContainer}
            onClick={() => {
              setPayanaReceipts([]);
              setSelectedMovement({});
            }}
          >
            <ChevronLeftIcon color="#5925DC" width="20px" height="20px" />
            <Typography style={styles.backText}>Volver</Typography>
          </div>
          <div style={styles.movementAliasContainer}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "600", color: "#475467" }}
            >
              {movementAlias}
            </Typography>
          </div>
        </>
      )}
      <div style={styles.movementsContainer}>
        {pageLoading ? (
          <div
            className="table-skeleton-page-initial-pageLoading"
            style={{ width: "100%", marginBottom: "16px" }}
          >
            <TableSkeleton />
          </div>
        ) : objectIsEmpty(bankMovements) ? (
          <MovementsEmptyState reconciliationOption={reconciliationOption} />
        ) : (
          <>
            {payanaReceipts.length > 0
              ? payanaReceipts.map((movement) => {
                  return (
                    <PayanaReceiptItem
                      selectedMovement={selectedMovement}
                      movement={movement}
                      setSelectedMovement={setSelectedMovement}
                      handleSelectMovement={handleSelectMovement}
                      setPayanaReceipts={setPayanaReceipts}
                    />
                  );
                })
              : bankMovements.map((movement) => {
                  return (
                    <BankMovementItem
                      selectedMovement={selectedMovement}
                      movement={movement}
                      setSelectedMovement={setSelectedMovement}
                      handleSelectMovement={handleSelectMovement}
                    />
                  );
                })}
          </>
        )}
      </div>
      {!objectIsEmpty(bankMovements) && !pageLoading && (
        <div className="paginationNav" style={{ marginBottom: "8px" }}>
          <span style={styles.paginationFont}>
            Página <strong>{pageIndex}</strong> de <strong>{totalPages}</strong>
          </span>
          <SecondaryButton
            isDisable={pageIndex <= 1}
            action={() => {
              setPageIndex((prev) => prev - 1);
            }}
            icon={
              <ArrowLeftIcon color={pageIndex <= 1 ? "#EAECF0" : "#344054"} />
            }
            width={40}
            style={{ padding: 0 }}
          />
          <SecondaryButton
            isDisable={pageIndex >= totalPages}
            action={() => {
              setPageIndex((prev) => prev + 1);
            }}
            icon={
              <RightArrowIcon
                color={pageIndex >= totalPages ? "#EAECF0" : "#344054"}
              />
            }
            width={40}
            style={{ padding: 0 }}
          />
        </div>
      )}
    </div>
  );
};
