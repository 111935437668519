import { useCallback } from "react";
import { NumericFormat } from "react-number-format";
import { MenuItem, Select } from "@mui/material";
import { debounce, daysFreeTrial } from "./";

export const boSubcriptionColumns = ({
  handleChangeSubscription,
  openBillingModal,
  companySelected,
  billings,
}) => [
  {
    field: "type",
    Header: "Modulo",
    id: "type",
    accessor: "type",
    disableSortBy: true,
    Cell: (column) => {
      const row = column.cell.row;
      const displayModule = {
        expenses: "Egreso",
        treasury: "Tesoreria",
        causation: "Causacion",
      };
      return (
        <p style={{ margin: 0, textTransform: "none" }}>
          {displayModule[row.values.type]}
        </p>
      );
    },
  },
  {
    field: "features",
    Header: "Suscripción",
    headerName: "features",
    id: "features",
    disableSortBy: true,
    Cell: ({ cell }) => {
      const row = cell.row;
      const { features } = row.original;
      const textByMonth = {
        1: "Mensual",
        12: "Anual",
      };
      return (
        <Select
          displayEmpty
          value={row.original.featureId}
          onChange={(e) => {
            handleChangeSubscription(row.original, e.target.value, "feature");
          }}
          renderValue={(value) => {
            if (!value) return "Selecciona una suscripción";
            const feature = features.find((f) => f.id === value);
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "85%",
                }}
              >
                <p style={{ margin: "0px" }}>
                  {`${textByMonth[feature?.months]}`}
                </p>
                <NumericFormat
                  value={feature?.price || 0}
                  isNumericString
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  fixedDecimalScale={true}
                  prefix={"$"}
                  displayType={"text"}
                />
              </div>
            );
          }}
          sx={{
            fontSize: "13px",
            width: "100%",
            height: "100%",
            minWidth: "200px",
            maxWidth: "200px",
            "& .MuiInputBase-root": {
              width: "100%",
            },
            "& .MuiSelect-select": {
              padding: "5px  10px !important",
              width: "100%",
              height: "100%",
              maxHeight: "50px",
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          {features.map((feature) => (
            <MenuItem
              key={feature.id}
              value={feature.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {`${textByMonth[feature?.months]}`}
              <NumericFormat
                value={feature?.price || 0}
                isNumericString
                thousandSeparator={"."}
                decimalSeparator={","}
                fixedDecimalScale={true}
                prefix={"$"}
                displayType={"text"}
              />
            </MenuItem>
          ))}
        </Select>
      );
    },
  },
  {
    field: "state",
    Header: "Estado",
    headerName: "state",
    id: "state",
    disableSortBy: true,
    Cell: ({ cell }) => {
      const row = cell.row;
      return row.original.status ? (
        <Select
          value={row.original.status}
          onChange={(e) => {
            handleChangeSubscription(row.original, e.target.value, "status");
          }}
          sx={{
            fontSize: "13px",
            width: "100%",
            height: "100%",
            minWidth: "110px",
            maxWidth: "110px",
            "& fieldset": {
              "&:focus .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
            "& .MuiSvgIcon-root": {
              display: "none",
            },
            "& .MuiInputBase-root": {
              width: "100%",
            },
            "& .MuiSelect-select": {
              padding: "5px  10px !important",
              width: "100%",
              height: "100%",
              maxHeight: "50px",
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          <MenuItem value={null} />
          <MenuItem value={"trial"} disabled>
            Prueba
          </MenuItem>
          <MenuItem value={"active"}>Activo</MenuItem>
          <MenuItem value={"inactive"}>Inactivo</MenuItem>
          <MenuItem value={"defeated"} disabled>
            Cancelado
          </MenuItem>
        </Select>
      ) : (
        "-"
      );
    },
  },
  {
    field: "freeTrial",
    Header: "Dias restantes de prueba",
    headerName: "Dias restantes de prueba",
    id: "freeTrial",
    disableSortBy: true,
    Cell: ({ cell }) => {
      const row = cell.row;
      const debouncedHandleChange = useCallback(
        debounce((row, floatValue, field) => {
          handleChangeSubscription(row, floatValue, field);
        }, 1000),
        [handleChangeSubscription]
      );

      return row.original.freeDays === null ? (
        "-"
      ) : (
        <NumericFormat
          style={{
            borderRadius: "8px",
            padding: "5px 10px",
            border: "1px solid rgb(118, 118, 118)",
          }}
          value={daysFreeTrial(
            row.original.freeDays,
            row.original.freeTrialStartAt
          )}
          isNumericString
          onValueChange={(values) => {
            const { floatValue } = values;
            debouncedHandleChange(row.original, floatValue, "freeDays");
          }}
        />
      );
    },
  },
  {
    field: "price",
    Header: "Precio",
    headerName: "price",
    id: "price",
    disableSortBy: true,
    Cell: ({ cell }) => {
      const row = cell.row;

      const debouncedHandleChange = useCallback(
        debounce((row, floatValue, field) => {
          handleChangeSubscription(row, floatValue, field);
        }, 1000),
        [handleChangeSubscription]
      );

      return row.original.discount === null ? (
        "-"
      ) : (
        <NumericFormat
          style={{
            borderRadius: "8px",
            padding: "5px 10px",
            border: "1px solid rgb(118, 118, 118)",
          }}
          value={row.original.price}
          isNumericString
          thousandSeparator={"."}
          decimalSeparator={","}
          prefix={"$"}
          fixedDecimalScale={true}
          onValueChange={(values) => {
            const { floatValue } = values;
            debouncedHandleChange(row.original, floatValue, "price");
          }}
        />
      );
    },
  },
  {
    field: "discount",
    Header: "Descuento",
    headerName: "Descuento",
    id: "discount",
    disableSortBy: true,
    Cell: ({ cell }) => {
      const row = cell.row;

      const debouncedHandleChange = useCallback(
        debounce((row, floatValue, field) => {
          handleChangeSubscription(row, floatValue, field);
        }, 1000),
        [handleChangeSubscription]
      );

      return row.original.discount === null ? (
        "-"
      ) : (
        <NumericFormat
          style={{
            borderRadius: "8px",
            padding: "5px 10px",
            border: "1px solid rgb(118, 118, 118)",
          }}
          value={row.original.discount}
          isNumericString
          thousandSeparator={"."}
          decimalSeparator={","}
          prefix={"$"}
          fixedDecimalScale={true}
          onValueChange={(values) => {
            const { floatValue } = values;
            debouncedHandleChange(row.original, floatValue, "discount");
          }}
        />
      );
    },
  },
  {
    field: "retentions",
    Header: "Retenciones",
    headerName: "Retenciones",
    id: "retentions",
    disableSortBy: true,
    Cell: ({ cell }) => {
      const row = cell.row;
      return row.original.retention === null ? (
        "-"
      ) : (
        <NumericFormat
          prefix={"%"}
          value={+companySelected?.retentions || 0}
          isNumericString
          thousandSeparator={"."}
          decimalSeparator={","}
          fixedDecimalScale={true}
          displayType={"text"}
        />
      );
    },
  },
  {
    field: "amountCollection",
    Header: "Monto a cobrar",
    headerName: "Monto a cobrar",
    id: "amountCollection",
    disableSortBy: true,
    Cell: ({ cell }) => {
      const row = cell.row;
      const price = row.original.amount || 0;
      return (
        <NumericFormat
          value={+price.toFixed(2)}
          isNumericString
          thousandSeparator={"."}
          decimalSeparator={","}
          fixedDecimalScale={true}
          prefix={"$"}
          displayType={"text"}
        />
      );
    },
  },
  {
    field: "billing",
    Header: "Facturacion",
    headerName: "Facturacion",
    id: "billing",
    disableSortBy: true,
    Cell: ({ cell }) => {
      const row = cell.row;
      if (cell.row.original.type === "causation") {
        return (
          <Select
            value={row.original.billingId}
            onChange={(e) => {
              handleChangeSubscription(row.original, e.target.value, "billing");
            }}
            sx={{
              fontSize: "13px",
              width: "100%",
              height: "100%",
              minWidth: "110px",
              maxWidth: "110px",
              "& .MuiInputBase-root": {
                width: "100%",
              },
              "& .MuiSelect-select": {
                padding: "5px  10px !important",
                width: "100%",
                height: "100%",
                maxHeight: "50px",
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            <MenuItem value={null} />
            {billings?.map((billing) => (
              <MenuItem value={billing.id}>
                {billing?.accountantUserId ? "Contador" : "Empresa"}
              </MenuItem>
            ))}
          </Select>
        );
      } else if (companySelected?.billing_id) {
        return <p style={{ margin: "0px" }}>Empresa</p>;
      } else {
        return "-";
      }
    },
  },
];
