import React, { useState } from "react";
import * as SubscriptionService from "services";
import { toast } from "react-toastify";
import { TrackJS } from "trackjs";
import { CrownIcon } from "assets";
import { PrimaryButton } from "commons/buttons";
import {
  ContactHelp,
  Footer,
  Features,
  CardInformation,
  SubscriptionTitle,
  SubscriptionIcon,
  TransactionCost,
  TextAddEgress,
} from "./";
import { toastOptions, TITLE_FEATURE, TITLE, PRICE_FEATURE } from "constants";
import { DISCOUNT, SUBSCRIPTION_TYPE } from "constants";
import { ANNUAL_TREASURY } from "constants";

export const ModalSuscriber = ({
  type,
  features,
  handleChangeStep,
  getInvoicesSubscriptionPending,
  subscription,
}) => {
  const featureFormatted = features?.map((feature) => ({
    title: TITLE_FEATURE[feature?.months],
    price: PRICE_FEATURE[type][feature?.months],
    discount: DISCOUNT[feature?.months],
    value: feature,
    onClick: () => handlePlanSelected(feature),
  }));

  const [planSelected, setPlanSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handlePlanSelected = (value) => {
    setPlanSelected(value);
  };

  const handleSubscription = async () => {
    let featureEgressId;
    try {
      setIsLoading(true);

      if (SUBSCRIPTION_TYPE.EXPENSES === type) {
        const subscriptionTreasury = subscription?.companySubscriptions?.find(
          (subscription) =>
            subscription.featureType === SUBSCRIPTION_TYPE.TREASURY
        );

        const monthsSubscriptionTreasury =
          subscriptionTreasury?.featureName === ANNUAL_TREASURY ? 12 : 1;

        const featureEgress = features?.find(
          (feature) => feature.months === monthsSubscriptionTreasury
        );

        featureEgressId = featureEgress?.id;
      }

      const body = {
        subscribes: [
          {
            featureId: featureEgressId ? featureEgressId : planSelected.id,
          },
        ],
      };
      const resp = await SubscriptionService.createSubcription(body);
      if (resp === "Subscriptions stored") {
        await getInvoicesSubscriptionPending();
        handleChangeStep("success");
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Error al suscribirse", toastOptions);
      TrackJS.console.error(error);
    }
  };

  const isDisabled = () => {
    if (SUBSCRIPTION_TYPE.EXPENSES === type) return false;

    return !planSelected || planSelected === null || !planSelected?.id;
  };

  return (
    <>
      <SubscriptionIcon icon={<CrownIcon size="medium" />} />
      <SubscriptionTitle title={TITLE[type]} />
      <div
        style={{
          width: "474px",
          padding: "16px",
          borderRadius: "8px",
          border: "1px solid #D0D5DD",
        }}
      >
        <CardInformation type={type} />
        {SUBSCRIPTION_TYPE.EXPENSES !== type && (
          <Features
            features={featureFormatted}
            title={"Nuestros planes"}
            planSelected={planSelected}
          />
        )}
        {SUBSCRIPTION_TYPE.TREASURY === type && <TransactionCost />}
        {SUBSCRIPTION_TYPE.EXPENSES === type && (
          <TextAddEgress
            treasury={subscription?.companySubscriptions?.find(
              (feature) => feature?.featureType === SUBSCRIPTION_TYPE.TREASURY
            )}
          />
        )}
        <Footer>
          <PrimaryButton
            text={"Suscribirme"}
            style={{ width: "100%", fontSize: "16px" }}
            action={handleSubscription}
            loading={isLoading}
            isDisable={isDisabled()}
          />
          <ContactHelp type={type} />
        </Footer>
      </div>
    </>
  );
};
