import React, { useContext, useEffect } from "react";
import { styles } from "../styles";
import { SearchIcon } from "assets";
import {
  Autocomplete,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ValidationMessage } from "commons";
import { reconciliationsDebtColumns, searchGroupText } from "../utils";
import { ReconciliationsContext } from "contexts";
import { CustomDataGrid } from "commons/modals/ExpandAssistedExpenses/components";
import { useAssistedExpenses } from "hooks";
import { NumericFormat } from "react-number-format";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import { Spinner } from "react-bootstrap";

export const AccountingSupplierForm = ({
  submitForm,
  createRequestInProcess,
  register,
  selectedDocumentType,
  setSelectedDocumentType,
  setCostCenterMandatory,
  documentTypes,
  formState,
  paymentMethods,
  setSelectedPaymentMethod,
  setValue,
  getPaymentMethodOptions,
  selectedPaymentMethod,
  costCenters,
  setSelectedCostCenter,
  costCenterMandatory,
  formValues,
  prevItems,
  textareaRef,
  comment,
  setComment,
  advanceAmount,
  setIsMarkAsReconciledModalVisible,
  setAdvanceAmount,
  selectedMovement,
  changeAdvanceAmount,
  setSelectedRows,
  accountingType,
  accountingAccountOptions,
}) => {
  const { reconciliationsScreen } = useContext(ReconciliationsContext);

  const {
    selectedRows,
    toggleSelectedRow,
    handleOnChangeAmountRegister,
    isChecked,
    setItems,
    items,
  } = useAssistedExpenses({
    isAssistedEgress: false,
    movementAmount:
      selectedMovement.amount < 0
        ? selectedMovement.amount * -1
        : selectedMovement.amount,
  });

  useEffect(() => {
    setItems(prevItems);
  }, [prevItems]);

  useEffect(() => {
    setSelectedRows(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    if (
      selectedRows.reduce((acc, row) => acc + row.amountRegister, 0) <
      advanceAmount
    ) {
      setAdvanceAmount(
        advanceAmount -
          selectedRows.reduce((acc, row) => acc + row.amountRegister, 0)
      );
    } else if (
      selectedRows.reduce((acc, row) => acc + row.amountRegister, 0) ===
      advanceAmount
    ) {
      setAdvanceAmount(null);
    } else {
      changeAdvanceAmount(selectedMovement.amount);
    }

    if (selectedRows.length === 0) {
      changeAdvanceAmount(selectedMovement.amount);
    }
  }, [selectedRows, items]);

  return (
    <form onSubmit={submitForm} style={styles.fullWidth}>
      {accountingType === "accountingReceipt" && (
        <>
          <div style={{ ...styles.fullWidth, marginBottom: "8px" }}>
            <Typography sx={styles.formTitle}>
              Cuenta contable de contraparte
            </Typography>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Select
                name="accountingAccount"
                size="small"
                variant="outlined"
                displayEmpty={true}
                {...register("accountingAccount", { required: true })}
                style={{
                  fontSize: "14px",
                  backgroundColor: "#FFFFFF",
                  color: formValues.accountingAccount === "" && "#98A2B3",
                  width: "100%",
                }}
                inputProps={{
                  id: "accountingAccount",
                }}
                value={formValues.accountingAccount}
              >
                <MenuItem
                  disabled
                  key={"type"}
                  value={""}
                  style={{ fontSize: "14px" }}
                >
                  Selecciona del listado
                </MenuItem>
                {accountingAccountOptions.map((option) => (
                  <MenuItem
                    key={option.Code}
                    value={option.Code}
                    style={{ fontSize: "14px" }}
                  >
                    {option.Name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <ValidationMessage
              errors={formState.errors}
              field="accountingAccount"
            />
          </div>
          <div style={{ ...styles.fullWidth, marginBottom: "8px" }}>
            <Typography sx={styles.formTitle}>Monto</Typography>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div
                style={{
                  width: "max-content",
                  border: "1px solid #D0D5DD",
                  borderRadius: "8px 0px 0px 8px",
                  height: "40px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  fontSize: "14px",
                  alignItems: "center",
                  display: "flex",
                  color: "#667085",
                }}
              >
                {selectedMovement.amount > 0 ? "Crédito" : "Débito"}
              </div>
              <NumericFormat
                style={{
                  width: "100%",
                  border: "1px solid #D0D5DD",
                  borderRadius: "0px 8px 8px 0px",
                  height: "40px",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  borderLeftWidth: "0px",
                }}
                value={
                  selectedMovement.amount < 0
                    ? selectedMovement.amount * -1
                    : selectedMovement.amount
                }
                {...register("amount", { required: false })}
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                prefix="$"
                disabled
              />
            </div>
            <ValidationMessage errors={formState.errors} field="amount" />
          </div>
        </>
      )}
      <div style={styles.formContainer}>
        <div style={styles.fullWidth}>
          <Typography sx={styles.formTitle}>
            {accountingType === "accountingReceipt"
              ? "Tipo de comprobante"
              : "Tipo"}
          </Typography>
          <Select
            disabled={createRequestInProcess}
            style={{ fontSize: "14px", width: "100%" }}
            size="small"
            value={selectedDocumentType || ""}
            required={true}
            displayEmpty
            {...register("documentType", {
              required: true,
              onChange: (e) => {
                setSelectedDocumentType(e.target.value);
                setCostCenterMandatory(
                  e.target.value.UseCostCenter &&
                    e.target.value.CostCenterMandatory
                );
              },
            })}
          >
            <MenuItem disabled style={{ fontSize: "14px" }} value="">
              Seleccione de la lista desplegable
            </MenuItem>
            {documentTypes?.map((documentType) => {
              return (
                <MenuItem
                  key={documentType.id}
                  style={{ fontSize: "14px" }}
                  value={documentType}
                >
                  {documentType.label}
                </MenuItem>
              );
            })}
          </Select>
          <ValidationMessage errors={formState.errors} field="documentType" />
        </div>
        {accountingType !== "accountingReceipt" && (
          <div style={styles.fullWidth}>
            <Typography sx={styles.formTitle}>Forma de pago</Typography>
            <Autocomplete
              options={paymentMethods}
              disabled={createRequestInProcess}
              size="small"
              sx={styles.fullWidth}
              freeSolo
              getOptionLabel={(option) => {
                return option?.Name || "";
              }}
              onChange={(e, option) => {
                if (option?.ACPaymentMeanID) {
                  setSelectedPaymentMethod(option);
                  setValue("paymentMethod", option);
                } else {
                  setSelectedPaymentMethod(null);
                  setValue("paymentMethod", null);
                }
              }}
              onInputChange={(event) => {
                if (event?.target?.value?.length >= 3) {
                  getPaymentMethodOptions(event.target.value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: 14 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={"Buscar forma de pago"}
                  {...register("paymentMethod", {
                    required: true,
                  })}
                />
              )}
              value={selectedPaymentMethod || ""}
            />
            <ValidationMessage
              errors={formState.errors}
              field="paymentMethod"
            />
          </div>
        )}
      </div>
      {selectedDocumentType && selectedDocumentType.UseCostCenter && (
        <>
          <Typography
            sx={{
              ...styles.formTitle,
              marginTop: "8px",
            }}
          >
            Centro de costos{" "}
            {accountingType === "accountingReceipt" && "(opcional)"}
          </Typography>
          <Autocomplete
            options={costCenters}
            size="small"
            sx={styles.fullWidth}
            disabled={createRequestInProcess}
            freeSolo
            getOptionLabel={(option) => {
              return option?.name ? option?.name + " " + option?.code : "";
            }}
            onChange={(e, option) => {
              if (option?.id) {
                setSelectedCostCenter(option);
              } else {
                setSelectedCostCenter(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 14 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder={"Buscar centro de costos"}
                {...register("costCenter", {
                  required:
                    accountingType === "accountingReceipt"
                      ? false
                      : costCenterMandatory,
                })}
              />
            )}
            value={formValues.costCenter || ""}
          />
          <ValidationMessage errors={formState.errors} field="costCenter" />
        </>
      )}
      {items.length > 0 && accountingType !== "accountingReceipt" && (
        <>
          <Typography
            sx={{
              ...styles.formTitle,
              marginTop: "12px",
            }}
          >
            Deuda pendiente{" "}
            {searchGroupText[
              reconciliationsScreen.beneficiaryFilter.value.type
            ] || "proveedor"}{" "}
            <strong>
              {reconciliationsScreen.beneficiaryFilter.value.fullName}
            </strong>
          </Typography>
          <div style={{ maxWidth: "max-content" }}>
            <CustomDataGrid
              columns={reconciliationsDebtColumns(
                handleOnChangeAmountRegister,
                toggleSelectedRow,
                isChecked
              )}
              rows={items}
            />
          </div>
        </>
      )}

      <Typography sx={{ ...styles.formTitle, marginTop: "8px" }}>
        Comentarios (opcional)
      </Typography>
      <textarea
        ref={textareaRef}
        style={styles.textArea}
        disabled={createRequestInProcess}
        name="comments"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        {...register("comments", {
          required: false,
          onChange: (e) => {
            setComment(e.target.value);
          },
        })}
      />
      <ValidationMessage errors={formState.errors} field="comments" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          justifyContent:
            selectedRows.length > 0 && advanceAmount
              ? "space-between"
              : "flex-end",
          alignItems: "center",
          marginTop: "8px",
          position: "sticky",
          bottom: "-4px",
          backgroundColor: "#FFFFFF",
          marginLeft: "-16px",
          marginRight: "-16px",
          padding: "8px 16px 8px 16px",
        }}
      >
        {advanceAmount && accountingType !== "accountingReceipt" && (
          <div style={styles.advanceContainer}>
            <Typography sx={styles.advanceTitle}>
              Se creará un anticipo por:
            </Typography>
            <Typography sx={styles.advanceAmount}>
              <NumericFormat
                value={advanceAmount}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
                decimalScale={2}
              />
            </Typography>
          </div>
        )}
        <div style={styles.formButtonsContainer}>
          <SecondaryButton
            text="Marcar como conciliado"
            action={setIsMarkAsReconciledModalVisible}
            width="182px"
            isDisable={createRequestInProcess}
          />
          <PrimaryButton
            text={
              createRequestInProcess ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Registrar"
              )
            }
            type="submit"
            width="182px"
            isDisable={createRequestInProcess}
          />
        </div>
      </div>
    </form>
  );
};
